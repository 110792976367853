import "../styles/index.scss";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/js/utils";
import * as axios from "axios";
import * as validate from "validate.js";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";
import format from "date-fns/format";
import Carousel from "bootstrap/js/dist/carousel";
const api = axios.default;
let hasErrors = false;
const itis = {};
const webinarList = {
  today: [],
  tomorrow: [],
  select: "today",
};
const data = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  occurrence_id: "",
  tz: "",
  country: "",
  webinarId: "",
};

// let language = "ar";
// const innerTranslate = (querySelector, datasetProp, prop) => {
//   const elements = document.querySelectorAll(querySelector);
//   for (let element of elements) {
//     if (element.dataset[datasetProp].indexOf(".") == -1) {
//       element[prop] = locales[language][element.dataset[datasetProp]];
//       continue;
//     }
//     const deepEl = element.dataset[datasetProp].split(".");
//     element[prop] = locales[language][deepEl[0]][deepEl[1]];
//   }
// };
// const translate = () => {
//   innerTranslate("[data-i18n]", "i18n", "innerHTML");
//   innerTranslate("[data-i18n-placeholder]", "i18nPlaceholder", "placeholder");
//   innerTranslate("[data-i18n-value]", "i18nValue", "value");
// };

const initPhoneInputs = () => {
  const elements = document.querySelectorAll("[data-phone-input]");

  for (let element of elements) {
    itis[element.dataset.phoneInput] = intlTelInput(element, {
      preferredCountries: ["eg", "ly", "tn", "ma", "dz", "sy", "bh", "jo", "ae", "ps", "iq", "lb", "kw", "om", "ye", "sa"],
      excludeCountries: ["kp", "il"],
      searchCountryFlag: true,
      searchCountryField: "all",
      maxLength: 15,
      enablePlaceholder: true,
      formatOnDisplay: true,
      separateDialCode: true,
      initialCountry: "auto",
      geoIpLookup: (success, failure) => {
        api
          .get("https://ipinfo.io", {
            headers: {
              "Content-Type": "jsonp",
            },
            params: {
              token: "f189bf262d9748",
            },
          })
          .then((res) => {
            success(res && res.data.country ? res.data.country : "ae");
          })
          .catch((err) => {
            failure(err);
          });
      },
    });
  }
};
const initForm = () => {
  const forms = document.querySelectorAll("[data-form]");
  addFormEventListeners(forms);
  api
    .get("https://students.englease.com/webinars-list")
    .then((res) => {
      const output = [];
      for (let w of res.data.webinars) {
        output.push({
          ...w.occurrences.map((o) => {
            return {
              webinarId: w.id.toString(),
              occurrenceId: o.occurrence_id,
              startTime: new Date(o.start_time),
              original_timezone: w.timezone,
            };
          })[0],
        });
      }
      for (let item of output) {
        let date = new Date(item.startTime);
        if (isToday(date, Intl.DateTimeFormat().resolvedOptions().timeZone)) {
          webinarList.today.push(item);
          continue;
        } else if (isTomorrow(date, Intl.DateTimeFormat().resolvedOptions().timeZone)) {
          if (!webinarList.today.find((o) => o.webinarId == item.webinarId)) {
            webinarList.tomorrow.push(item);
          }
        }
      }
      const todayButtons = document.querySelectorAll('[data-webinar="today"]');
      const tomorrowButtons = document.querySelectorAll('[data-webinar="tomorrow"]');
      const todayTimes = document.querySelectorAll('[data-times-container="today"]');
      const tomorrowTimes = document.querySelectorAll('[data-times-container="tomorrow"]');
      console.log(todayButtons, tomorrowButtons);
      if (!webinarList.today.length) {
        hideElements(todayButtons);
        hideElements(todayTimes);
        showElements(tomorrowButtons);
        showElements(tomorrowTimes);
        webinarList.select = "tomorrow";
      }
      if (!webinarList.tomorrow.length) {
        hideElements(tomorrowButtons);
        hideElements(tomorrowTimes);
        showElements(todayButtons);
        showElements(todayTimes);
        webinarList.select = "today";
      }

      if (webinarList.select == "today") {
        todayButtons.forEach((b) => {
          addClass(b, "cET-blue-bg");
          removeClass(b, "cET-blue-01-bg");
        });
      }

      if (webinarList.select == "tomorrow") {
        tomorrowButtons.forEach((b) => {
          addClass(b, "cET-blue-bg");
          removeClass(b, "cET-blue-01-bg");
        });
      }
      createTimes(todayTimes, webinarList.today);
      createTimes(tomorrowTimes, webinarList.tomorrow);
    })
    .catch((err) => {
      throw err;
    });
};
/**
 *
 * @param {HTMLDivElement} container
 */
const createTimes = (containers, times) => {
  if (!times.length) return;
  for (let container of containers) {
    for (let time of times) {
      const timeEl = document.createElement("button");
      timeEl.innerHTML = format(time.startTime, "HH:mm");
      timeEl.className = "btn ET-unselected-time pt-2 ET-time-picker-btn-tag";
      timeEl.dataset["timeHolder"] = true;
      timeEl.onclick = () => {
        data.occurrence_id = time.occurrenceId;
        data.webinarId = time.webinarId;
        document.querySelectorAll('[data-form-value="time"]').forEach((element) => {
          element.innerHTML = timeEl.innerHTML;
        });
        document.querySelectorAll("[data-form-register]").forEach((element) => {
          removeClass(element, "disabled");
        });
        document.querySelectorAll("[data-time-holder]").forEach((element) => {
          removeClass(element, "ET-selected-time");
          addClass(element, "ET-unselected-time");
        });
        addClass(timeEl, "ET-selected-time");
        removeClass(timeEl, "ET-unselected-time");
      };
      container.appendChild(timeEl);
    }
  }
};
const hideElements = (elements) => {
  for (let element of elements) {
    addClass(element, "d-none");
  }
};

const showElements = (elements) => {
  for (let element of elements) {
    removeClass(element, "d-none");
  }
};
const constraints = {
  name: () => {
    return {
      presence: { allowEmpty: false, message: "الإسم الكامل مطلوب" },
      format: {
        pattern: /\w+\s+\w+.+/,
        message: "اضف الاسم بالكامل",
      },
    };
  },
  email: () => {
    return {
      presence: { allowEmpty: false, message: "البريد الالكتروني مطلوب" },
      email: {
        message: "البريد الإلكتروني غير صالح",
      },
    };
  },

  phone: () => {
    return {
      presence: { allowEmpty: false, message: "رقم الهاتف مطلوب" },
    };
  },
};
/**
 * @param {HTMLFormElement[]} forms
 */
const addFormEventListeners = (forms) => {
  for (let form of forms) {
    const elements = form.querySelectorAll("[data-form-element]");
    for (let element of elements) {
      element.addEventListener("keyup", () => {
        if (!hasErrors) return;
        const errorsContainers = document.querySelectorAll("[data-form-errors]");
        hideElements(errorsContainers);
        hasErrors = false;
      });
    }
    form.addEventListener("submit", (event) => {
      event.preventDefault();
      const payload = {
        name: form.elements["name"].value,
        email: form.elements["email"].value,
        phone: itis[form.elements["phone"].dataset.phoneInput].getNumber(),
      };
      console.log(itis);
      const valid = validate(payload, {
        name: constraints.name(),
        email: constraints.email(),
        phone: constraints.phone(),
      });

      if (typeof valid != "undefined") {
        hasErrors = true;
        const errorElements = document.querySelectorAll("[data-form-errors]");
        for (let errorElement of errorElements) {
          if (valid[errorElement.dataset.formErrors]) {
            errorElement.innerHTML = valid[errorElement.dataset.formErrors][0].replace(
              capitalizeFirstLetter(errorElement.dataset.formErrors),
              ""
            );
            removeClass(errorElement, "d-none");
          }
        }
        return;
      }

      data.first_name = payload.name.split(" ")[0];
      document.querySelectorAll('[data-form-value="name"]').forEach((element) => {
        element.innerHTML = data.first_name;
      });
      data.last_name = payload.name.split(" ")[1];
      data.email = payload.email;
      data.phone = payload.phone.replace("+", "");
      data.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      data.country = itis[form.elements["phone"].dataset.phoneInput].getSelectedCountryData().iso2.toUpperCase();
      window.gtag("event", "conversion", { send_to: "AW-734075898/f1nvCPSgrbsDEPq3hN4C" });
      window.ga("send", "event", "Lead", "Webinar", "Form Submit");
      window.fbq("track", "Lead");
      showTimings(form);
      api.post(
        "https://hooks.zapier.com/hooks/catch/6959414/bkab4yl",
        {
          ...data,
          source: window.location.href,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
    });
  }
};
/**
 *
 * @param {HTMLButtonElement[]} buttons
 */
const addRegisterButtonsEventListeners = (buttons, timings, form) => {
  for (let button of buttons) {
    button.addEventListener("click", (event) => {
      event.preventDefault();
      const loader = showLoader(timings, form);

      api
        .post(`https://students.englease.com/webinar-register/${data.webinarId}`, data)
        .then(() => {
          showSuccess(loader, form);
          window.gtag("event", "conversion", { send_to: "AW-734075898/vUbECO6Or7sDEPq3hN4C" });
          window.ga("send", "event", "Scheduled", "Webinar", "Form Submit");
          window.fbq("track", "Schedule");
        })
        .catch((err) => {
          throw err;
        });
    });
  }
};
const addDayButtonsEventListeners = (buttons) => {
  for (let button of buttons) {
    button.addEventListener("click", (event) => {
      event.preventDefault();
      document.querySelectorAll("[data-webinar]").forEach((element) => {
        removeClass(element, "cET-blue-bg");
        addClass(element, "cET-blue-01-bg");
      });
      removeClass(event.target, "cET-blue-01-bg");
      addClass(event.target, "cET-blue-bg");
      document.querySelectorAll("[data-times-container]").forEach((element) => {
        addClass(element, "d-none");
      });
      const timesContainer = document.getElementById(event.target.dataset.timesContainerId);
      removeClass(timesContainer, "d-none");
    });
  }
};

/**
 * @param {HTMLFormElement} form
 */
const showTimings = (form) => {
  const timings = document.getElementById(form.dataset.formTime);
  addClass(form, "d-none");
  removeClass(timings, "d-none");
  const registerButtons = timings.querySelectorAll("[data-form-register]");
  const dayButtons = timings.querySelectorAll("[data-webinar]");
  addDayButtonsEventListeners(dayButtons);
  addRegisterButtonsEventListeners(registerButtons, timings, form);
};
const showLoader = (timings, form) => {
  addClass(timings, "d-none");
  const loader = document.getElementById(form.dataset.formLoading);
  removeClass(loader, "d-none");
  return loader;
};
const showSuccess = (loader, form) => {
  addClass(loader, "d-none");
  const success = document.getElementById(form.dataset.formSuccess);
  removeClass(success, "d-none");
};
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const hasClass = (el, className) => {
  if (el.classList) return el.classList.contains(className);
  return !!el.className.match(new RegExp("(\\s|^)" + className + "(\\s|$)"));
};

const addClass = (el, className) => {
  if (el.classList) el.classList.add(className);
  else if (!hasClass(el, className)) el.className += " " + className;
};

const removeClass = (el, className) => {
  if (el.classList) el.classList.remove(className);
  else if (hasClass(el, className)) {
    var reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
    el.className = el.className.replace(reg, " ");
  }
};
const initCarousels = () => {
  const carousels = document.querySelectorAll(".carousel");
  for (let carousel of carousels) {
    new Carousel(carousel);
  }
};
// translate();
initPhoneInputs();
initForm();
initCarousels();
